import { useEffect, useMemo, useState } from "react";

import { useRouter } from "next/router";

import {
  Autocomplete,
  Avatar,
  Box,
  Chip,
  TextField,
  Typography,
} from '@mui/material';

import { alpha } from '@mui/material/styles';

import debounce from 'lodash/debounce';

import axios from "axios";
import axiosInstance from "../src/axiosInstance";


const SearchAll = () => {
  const router = useRouter();
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetch = useMemo(
    () => debounce((value, source) => {
      setLoading(true);
      setOptions([]);
      axiosInstance.get(
        `/api/v2/contacts/?q=${value}`,
        {
          cancelToken: source?.token
        }
      ).then((response) => {
          const { data } = response;
          setOptions(data);
          setLoading(false);
        })
      }, 500),
    [],
  );

  useEffect(() => {
    const source = axios.CancelToken.source();

    fetch('', source)

    return () => {
      source.cancel("Cancelling in cleanup");
    }
  }, [fetch])

  return (
    <Autocomplete
      component={Autocomplete}
      options={options}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Search"
          variant="outlined"
          autoFocus
          fullWidth
          onChange={(event) => {
            setLoading(true);
            fetch(event.target.value);
          }}
          size="small"
          sx={{
            borderRadius: 8,
            '& .MuiInputBase-input': {
              color: 'white',
            },
            '& label.Mui-focused': {
              color: 'white',
            },
            '& .MuiInputLabel-root': {
              color: '#eee'
            },
            '& .MuiInput-root': {
              color: '#eee'
            },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: 'primary.main',
              },
              '&:hover fieldset': {
                borderColor: 'primary.main',
              },
              '&.Mui-focused fieldset': {
                borderColor: 'secondary.main',
              },
            },
          }}
        />
      )}
      fullWidth
      autoComplete
      // openOnFocus={true}
      loading={loading}
      onChange={(event, newValue) => {
        if (newValue) {
          router.push(`/contacts/${newValue.channel.id}/?activeTab=${newValue.channel.is_client ? 1 : 0}`);
        }
      }}
      filterOptions={(x) => x}
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.user)}
      groupBy={(option) => option.by}
      renderOption={(props, option) => (
        <Box sx={{ ml: -1 }} {...props} key={option.id}>
          <Box sx={{ py: 1 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <Avatar src={option.picture} sx={{ pl: 0 }}>{option.user[0]}</Avatar>
              <Typography color="text.secondary" sx={{ ml: 1 }}>{option.user}</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Chip label={option.channel.is_client ? 'Client' : 'Lead'} size="small" color={option.channel.is_client ? 'info' : 'primary'} />
              <Chip sx={{ ml: 0.5 }} label={option.make} size="small" variant="outlined" color="primary" />
              <Chip sx={{ ml: 0.5 }} label={option.service} size="small" variant="outlined" color="primary" />
            </Box>
          </Box>
        </Box>
      )}
      sx={{
        bgcolor: theme => alpha(theme.palette.common.white, 0.15),
        '&:hover': {
          bgcolor: theme => alpha(theme.palette.common.white, 0.25),
        },
        borderRadius: 2,
      }}
      ListboxProps={{
        sx: {
          bgcolor: 'secondary.light'
        },
      }}
    />
  );
}

export default SearchAll;
