import React from 'react';

import {
  Box,
  Skeleton,
} from '@mui/material';


const Loader = ({ height=80, count=5 }) => {
  return (
    <Box sx={{
      '& > *': {
        mb: 2,
      },
      pt: 2
    }}>
      {[...Array(count)].map((e, i) => <Skeleton key={i} variant="rectangular" width="100%" height={height} />)}
    </Box>
  );
}

export default Loader;
